
import ApiService from '@/core/services/ApiService';
import { defineComponent, ref } from 'vue';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { useBus } from '../../../../bus';
import { Field } from 'vee-validate';
import { DrawerComponent } from '@/assets/ts/components/_DrawerComponent';
import { VueCookieNext } from 'vue-cookie-next';
import { apiEndpoint } from '@/mixin/apiEndpoint';

export default defineComponent({
  name: 'kt-drawer-addunitOfCompetency',
  components: { Field },
  mixins: [apiEndpoint],
  data() {
    return {
      load: false,
      id: this.$route.params.id as any,
      unit_of_competency: [
        {
          id: '',
          unit_name: '',
        },
      ] as any,
      unitOfCompetency: {
        id: '',
        unit_name: '',
      } as any,
      loading: false,
      course_id: '' as any,
    };
  },
  async created() {
    this.emitter.on('unit-of-competency-add', async (course_id) => {
      this.course_id = course_id;
      // console.log('unit-of-competency-add');
      // console.log(this.course_id);
      await this.getUnitOfCompetencyList();
    });
  },
  methods: {
    addUnitOfCompetency() {
      this.unit_of_competency.push(
        JSON.parse(JSON.stringify(this.unitOfCompetency))
      );
    },
    removeUnitOfCompetency() {
      this.unit_of_competency.length--;
    },
    async getUnitOfCompetencyList() {
      this.load = true;
      await ApiService.get(
        this.VUE_APP_UNIT_OF_COMPETENCY_List_API +
          '?course_id=' +
          this.course_id
      )
        .then((response) => {
          if (response.data.data.length > 0) {
            this.unit_of_competency = response.data.data;
            this.load = false;
          } else {
            this.unit_of_competency = [
              {
                id: '',
                unit_name: '',
              },
            ];
            this.load = false;
          }
          console.log(this.unit_of_competency);

          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async formSubmit() {
      let formData = new FormData();
      formData.set(
        'unitOfCompetency_data',
        JSON.stringify(this.unit_of_competency)
      );
      let user_id = VueCookieNext.getCookie('_seip_user');
      formData.set('user_id', user_id.id);
      formData.set('course_id', this.course_id);

      this.loading = true;
      await ApiService.post(this.VUE_APP_UNIT_OF_COMPETENCY_SAVE_API, formData)
        .then((response) => {
          DrawerComponent?.hideAll();
          this.loading = false;
          if (response.data.status == 'success') {
            Swal.fire({
              title: 'Success!',
              text: response.data.data,
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            }).then(() => {
              this.emitter.emit('unit-of-competency-added', true);
            });
          } else {
            let err = '';
            for (const field of Object.keys(response.data.errors)) {
              err += response.data.errors[field][0] + '<br>';
            }
            Swal.fire({
              title: 'Please check all the required field',
              html: err,
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: 'Close',
              customClass: {
                confirmButton: 'btn btn-danger',
              },
            });
          }
        })
        .catch(({ response }) => {
          this.loading = false;
          Swal.fire({
            title: 'Unknown error',
            html: response.data.error,
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Close',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          });
          console.log(response);
        });
    },
  },
});
