
import { defineComponent, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { VueCookieNext } from 'vue-cookie-next';
import { ScrollComponent } from '@/assets/ts/components/_ScrollComponent';
import { MenuComponent } from '@/assets/ts/components/MenuComponent';
import { version } from '@/core/helpers/documentation';
import { asideMenuIcons } from '@/core/helpers/config';
//import MainMenuConfig from "./Menu";

export default defineComponent({
  name: 'kt-menu',
  data() {
    return {
      MainMenuConfig: [
        {
          heading: 'menu',
          route: '/course',
          pages: [
            {
              heading: 'Add Course',
              route: '/course/courseInfo',
              fontIcon: 'fa-plus',
            },
            {
              heading: 'Course List',
              route: '/course/course-list',
              fontIcon: 'fa-search',
            },
            {
              heading: 'Search Course',
              route: '/course/searchCourse',
              fontIcon: 'fa-search',
            },

            {
              sectionTitle: 'Course Mapping',
              route: '/course/mapping',
              fontIcon: 'fa-plus',
              svgIcon: 'media/icons/duotune/general/gen019.svg',
              sub: [
                {
                  heading: 'Map Trainer',
                  route: '/course/mapping/trainer',
                },
                {
                  heading: 'Map Assessor',
                  route: '/course/mapping/assessor',
                },
              ],
            },

            // {
            //   heading: "Unique Course Details",
            //   route: "/course/uniqueCourse",
            //   fontIcon: "fa-search",
            // },
            // {
            //   heading: "Course Activity Report",
            //   route: "/course/activityReport",
            //   fontIcon: "fa-search",
            // },
          ],
        },
      ],
    };
  },
  components: {},
  async created() {
    await this.menucheck();
  },
  methods: {
    async menucheck() {
      let entity_type_id = VueCookieNext.getCookie('_seip_entity_type');
      if (entity_type_id == 1011) {
        console.log('working');
        this.MainMenuConfig = [
          {
            heading: 'menu',
            route: '/course',
            pages: [
              {
                heading: 'Course List',
                route: '/course/course-list',
                fontIcon: 'fa-search',
              },
            ],
          },
        ];
      }
      if (entity_type_id == 1010) {
        console.log('working');
        this.MainMenuConfig = [
          {
            heading: 'menu',
            route: '/course',
            pages: [
              {
                heading: 'Add Course',
                route: '/course/courseInfo',
                fontIcon: 'fa-plus',
              },
              {
                heading: 'Course List',
                route: '/course/course-list',
                fontIcon: 'fa-search',
              },
              {
                heading: 'Search Course',
                route: '/course/searchCourse',
                fontIcon: 'fa-search',
              },
              {
                heading: 'Course Activity Report',
                route: '/course/activityReport',
                fontIcon: 'fa-search',
              },
              {
                sectionTitle: 'Course Mapping',
                route: '/course/mapping',
                fontIcon: 'fa-plus',
                svgIcon: 'media/icons/duotune/general/gen019.svg',
                sub: [
                  {
                    heading: 'Map Trainer',
                    route: '/course/mapping/trainer',
                  },
                  {
                    heading: 'Map Assessor',
                    route: '/course/mapping/assessor',
                  },
                ],
              },
            ],
          },
        ];
      }
    },
  },
  setup() {
    const { t, te } = useI18n();
    const route = useRoute();
    const scrollElRef = ref<null | HTMLElement>(null);

    onMounted(() => {
      ScrollComponent.reinitialization();
      MenuComponent.reinitialization();
      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }
    });

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };

    return {
      hasActiveChildren,
      asideMenuIcons,
      version,
      translate,
    };
  },
});
